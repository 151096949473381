.table {
  thead tr {
    @apply text-sm text-left font-normal leading-5 bg-primary-main;
    // background-color: #ffed8a;

    span {
      @apply whitespace-nowrap;
    }
  }

  &-action {
    color: inherit;
    font-size: 14px;
  }

  &-action-delete {
    color: #e4033b;
  }

  &-action-edit {
    color: #009ef7;
  }

  &-action-refund {
    color: #00b67c;
  }

  &-action-process {
    color: #ffc700;
  }

  &-action-btn {
    padding: 0.2rem 0.4rem;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  &-action-btn:hover {
    background-color: #f5f8fa;
  }

  thead tr th {
    color: white;
    padding: 12px 10px;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  tbody td,
  tbody th {
    @apply text-sm text-left font-normal leading-5;
  }

  tbody tr:nth-child(odd) {
    td,
    th {
      background: white;
      padding: 12px 10px;
    }
  }

  tbody tr:nth-child(even) {
    td,
    th {
      background: #ffffff;
      padding: 12px 10px;
    }
  }

  tbody tr:hover {
    td,
    th {
      background-color: #f0f9ff;
    }
  }
}

.table > tbody > tr:last-child {
  border-bottom: 2px solid #6dc9f4;
}

.table-simple {
  thead tr {
    @apply text-xs text-left font-semibold bg-gray-f2 text-gray-4f;
  }

  span {
    @apply whitespace-nowrap;
  }

  tbody td,
  tbody th {
    @apply text-xs font-normal text-left;
  }

  thead tr th {
    padding: 12px 10px;
  }

  tbody tr:nth-child(odd) {
    td,
    th {
      background: white;
      padding: 12px 10px;
    }
  }

  tbody tr:nth-child(even) {
    td,
    th {
      background: white;
      padding: 12px 10px;
    }
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;
  }
}

.table-category {
  tbody .row-active {
    td,
    th {
      background: #bdecff !important;
    }
  }
}

.table-theme {
  thead tr {
    @apply text-xs text-left font-semibold bg-primary-main;
  }

  span {
    @apply whitespace-nowrap;
  }

  tbody td,
  tbody th {
    @apply text-xs font-normal text-left;
  }

  thead tr th {
    padding: 12px 10px;
  }

  tbody tr {
    td,
    th {
      background: white;
      padding: 12px 10px;
    }
  }

  // tbody tr:nth-child(even) {

  //   td,
  //   th {
  //     background: white;
  //     padding: 12px 10px;
  //   }
  // }

  tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  tbody tr:hover {
    td,
    th {
      background-color: #ffed8a;
    }
  }
}

.confeti-table span {
  font-weight: 700;
}

.confeti-table td,
.confeti-table th {
  text-align: right !important;
}

.confeti-table td:first-child,
.confeti-table th:first-child {
  text-align: left !important;
}

.confeti-table th:last-child {
  text-align: center !important;
  width: 34%;
}

.table-action-btn:hover {
  background-color: white;
}
