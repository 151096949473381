.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav > li {
  position: relative;
  display: block;
}
.nav-tabs > li.active > span {
  -webkit-box-shadow: 0 -2px 0 #5240e2;
  -moz-box-shadow: 0 -2px 0 #5240e2;
  box-shadow: 0 -2px 0 #5240e2;
  border-top-width: 0 !important;
  margin-top: 1px !important;
  font-weight: 700;
}
.nav-tabs > li.active > span,
.nav-tabs > li.active > span:focus,
.nav-tabs > li.active > span:hover {
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}
.nav-tabs > li > span {
  border-radius: 0;
  color: #333;
}
.nav-tabs > li > span {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 2px 2px 0 0;
}
.nav > li > span {
  position: relative;
  display: block;
  padding: 9px 10px 10px;
}
.nav:before,
.nav:after {
  content: ' ';
  display: table;
}
.nav:after {
  clear: both;
}
