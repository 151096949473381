.title {
  @apply font-semibold  text-base;
}
.title-bold {
  @apply text-base font-bold;
}
.caption-2 {
  @apply font-normal text-xs;
}
.paragraph-small {
  @apply text-xs font-normal;
}
.body-2 {
  @apply text-base font-normal;
}
.typography {
  @apply text-base font-normal;
}