.pagination {
  @apply flex justify-center w-full h-full space-x-1;
  .page-item {
    @apply text-sm relative  border border-gray-33 rounded-lg;

    .visually-hidden {
      @apply hidden;
    }
    &.active {
      @apply bg-primary text-white;
    }
    .page-link {
      min-width: 34px;
      height: 34px;
      @apply flex text-sm items-center justify-center px-1;
    }
  }
}
