@import 'https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body,
  html {
    @apply font-sans;
  }

  * {
    min-width: 0;
    min-height: 0;
  }
}
.bg-brand-gradient {
  @apply bg-primary-main;
}
.bg-brand-gradient-1 {
  @apply bg-primary-main;
}
.container {
  max-width: 1140px;
  @apply mx-auto;
}
.bg-overlay-menu {
  background-color: rgba(27, 28, 31, 0.15);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

[draggable='true'] {
  /*
   To prevent user selecting inside the drag source
  */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.text-line-camp-2 {
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
@import './assets/styles/react_datepicker_custom.scss';
@import './assets/styles/heading.scss';
@import './assets/styles/typography.scss';
@import './assets/styles/form.scss';
@import './assets/styles/button.scss';
@import './assets/styles/slick.scss';
@import './assets/styles/header-page.scss';
@import './assets/styles/table.scss';
@import './assets/styles/pagination.scss';
@import './assets/styles/tabs.scss';
@import './assets/styles/checkbox.scss';
@import './assets/styles/status.scss';
@import './assets/styles/switch.scss';

select > option.option-custom {
  font-family: inherit;
  font-size: 16px;
  line-height: 1rem;
  height: 2rem;
  padding: 0.75rem 1.5rem;
}

.rti--container {
  --rti-main: #495057 !important;
  padding: 0.5rem 1.5rem !important;
  font-size: 16px;
}

.editorClassName {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 0 10px;
}

// select {
//   -moz-appearance: none;
//   -webkit-appearance: none;
//   appearance: none;
//   background-image: url('./assets/svg/form_select_icon.svg');
//   background-repeat: no-repeat, repeat;
//   background-position: right 10px top 50%, 0 0;
//   background-size: .65em auto, 100%;
// }

.popup-export {
  width: 469px;
  height: 89px;
  position: relative;
  border-radius: 8px;
  z-index: 10;
}

.popup-export:after {
  content: '';
  height: 98%;
  width: 98%;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.popup-export:before {
  content: '';
  width: 16px;
  height: 27px;
  transform: rotate(45deg);
  // box-shadow: 0 1px 14px rgba(0, 0, 0, .2);
  background: #ffffff;
  position: absolute;
  z-index: 11;
}

/*
Different arrow positioning
*/
.popup-export.arrow-top:before {
  left: calc(79% - 10px);
  top: -8px;
}

.popup-export.arrow-right:before {
  top: calc(50% - 10px);
  right: -8px;
}

.popup-export.arrow-bottom:before {
  left: calc(50% - 10px);
  bottom: -8px;
}

.popup-export.arrow-left:before {
  top: calc(50% - 10px);
  left: -8px;
}

.user-info-img {
  &-upload {
    opacity: 0;
  }

  &:hover .user-info-img-upload {
    opacity: 1;
  }
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.__value-container {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 22px !important;

  &:focus {
    @apply shadow-input;
  }
}

@font-face {
  font-family: 'ProRoundedBold';
  src: local('SFProRounded'),
    url('./assets/font/SFProRounded/SF-Pro-Rounded-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'ProRoundedThin';
  src: local('SFProRounded'),
    url('./assets/font/SFProRounded/SF-Pro-Rounded-Thin.otf') format('truetype');
}

@font-face {
  font-family: 'ProRoundedSemibold';
  src: local('SFProRounded'),
    url('./assets/font/SFProRounded/SF-Pro-Rounded-Semibold.otf') format('truetype');
}

@font-face {
  font-family: 'ProRoundedRegular';
  src: local('SFProRounded'),
    url('./assets/font/SFProRounded/SF-Pro-Rounded-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'ProRoundedHeavy';
  src: local('SFProRounded'),
    url('./assets/font/SFProRounded/SF-Pro-Rounded-Heavy.otf') format('truetype');
}

@font-face {
  font-family: 'ProRoundedBlack';
  src: local('SFProRounded'),
    url('./assets/font/SFProRounded/SF-Pro-Rounded-Black.otf') format('truetype');
}

.hidden-checkbox {
  position: absolute;
  opacity: 0;
}

.custom-checkbox-label::after {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #f0f0f0;
  background-color: #f1f1f1;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  margin-left: 13px;
}

.custom-checkbox-label:hover::after {
  background-color: #f5f7fa;
  cursor: pointer;
}

.hidden-checkbox:checked + .custom-checkbox-label::after {
  @apply bg-primary;
  border: #f0f0f0;
}

.check_box_icon {
  display: none;
}

.hidden-checkbox:checked + .custom-checkbox-label .check_box_icon {
  display: block;
}

.react-select-container {
  .react-select__menu {
    border-radius: 9px;
    border-color: #f0f0f0;
    box-shadow: 0px 32px 48px -8px rgba(0, 0, 0, 0.1), 0px 0px 14px -4px rgba(0, 0, 0, 0.05),
      0px 40px 64px -12px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(16px);
  }

  .react-select__menu-list {
    padding: 12px 18px;
    max-height: 190px;

    > div {
      border-bottom: 1px solid #efefef;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.select__option--is-selected {
  background: #00c802 !important;
}

.select__menu {
  z-index: 10 !important;
}

.jodit-popup__content {
  max-height: 360px !important;

  &.jodit-option-list__content {
    max-height: 216px !important;
  }
}

.jodit-popup.jodit-option-list {
  position: absolute;
  top: calc(100% + 5px);
  left: -1px;
  right: -1px;
  display: none;

  li {
    padding: 8px 0;
    cursor: pointer;
    border-bottom: 1px solid #efefef;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.custom-jodit-form {
  .jodit-ui-combo-box__wrapper,
  .jodit-ui-input__wrapper {
    min-width: 300px;
  }

  .jodit-ui-combo-box__wrapper {
    position: relative;
  }
}

.jodit-wysiwyg_iframe {
  padding: 8px;
}

.tag-select-option::-webkit-scrollbar {
  width: 2px;
  max-height: 67px;
  height: 67px;
}

.tag-select-option::-webkit-scrollbar-thumb {
  background: #d9d9d9; /* Màu nền của thanh cuộn */
  border-radius: 2px; /* Độ cong góc của thanh cuộn */
}

.line-clamp-1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.modal .modal-confirm {
  background-image: url('./assets//images//popup-bg.png');
  background-position: top left;
  background-size: 216px;
}

.jodit-workplace ol,
.jodit-workplace ul {
  padding-left: 20px;
}

.checkbox-style {
  display: flex;
  gap: 10px;
  align-items: center;
}

.checkbox-style input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background-color: #ffffff;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
}

.checkbox-style input[type='checkbox']:checked {
  @apply bg-primary;
}

.checkbox-style input[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  top: 40%;
  left: 50%;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.checkbox-style input[type='checkbox']:checked::before {
  height: 16px;
}
.menu-list {
  counter-reset: number;
}
.menu-list > li {
  display: flex;
  gap: 4px;
  counter-increment: number;

  ul {
    counter-reset: sub-number;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      bottom: -8px;
      left: -20px;
      width: 1px;
      background-color: #eaeaea;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    > li {
      display: flex;
      gap: 4px;
      counter-increment: sub-number;

      &::before {
        content: counter(number) '.' counter(sub-number);
        width: 20px;
      }
    }
  }

  &::before {
    content: counter(number);
    width: 20px;
  }
}

.menu-active {
  color: #000;
  font-weight: 700;
}

.menu-item-active {
  position: relative;
  color: #000;

  * {
    color: #000;
  }

  &::before {
    color: #000;
  }

  &::after {
    content: '';
    position: absolute;
    top: 8px;
    height: 36px;
    left: -8px;
    right: 0;
    background-color: #f2f2f2;
    z-index: -1;
    border-radius: 4px;
  }
}

.menu-sub-active {
  position: relative;
  font-weight: 700;

  &::before {
    color: #000;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 36px;
    left: -8px;
    right: 0;
    background-color: #fef8e6;
    z-index: -1;
    border-radius: 4px;
  }
}
