.status {
  font-weight: 600;
  background-color: #f7f7f7;
  padding: 0.2rem 0.4rem;
  border-radius: 0.3rem;
}

.status-Send,
.status-Refunded,
.status-CashAdjusted {
  color: #ff4f00;
}

.status-Approval {
  color: #00ee00;
}

.status-User {
  color: #065535;
}

.status-Duplicated,
.status-InActive,
.status-4,
.status-Suspend,
.status-Lock,
.status-Canceled,
.status-Destroyed,
.status-Closed,
.status-NotRun_YesterdayNotRun,
.status-NotRun_InputDateInvalid,
.status-InvalidStatus,
.status-NotRun_InputDateIsHoliday,
.status-None,
.status-Stop,
.status-CLOSE {
  color: #82868b;
}

.status-Success,
.status-2,
.status-3,
.status-Successes,
.status-Succeeded,
.status-ACTIVATED,
.status-Received,
.status-Start,
.status-Active,
.status-ACTIVE,
.status-SUCCESS,
.status-IN_DURATION,
.status-Published,
.status-SuccessManual {
  color: #61cb2f;
}

.status-Fail,
.status-5,
.status-Deleted,
.status-Canceled,
.status-Cancel,
.status-Failed,
.status-End,
.status-ReEkyc,
.status-FAILED,
.status-Unexecuted,
.status-REACH_LIMIT,
.status-Unpublished,
.status-FailedObvious,
.status-FailedUnknown,
.status-Cancel {
  @apply text-red-accent;
}

.status-PendingApproval,
.status-ManualCheck,
.status-0,
.status-Created,
.status-INIT,
.status-Init,
.status-CREATED,
.status-Tradable,
.status-Pause,
.status-Pending,
.status-RefundProcessing {
  color: #009ef7;
}

.status-Init,
.status-Draft {
  color: #902fe8;
}

.status-Process,
.status-1,
.status-Receiving,
.status-Bulls,
.status-WaitForMatch,
.status-PendingApproval,
.status-WaitForWallet,
.status-Upcoming,
.status-Processing {
  color: #ffc700;
}

.status-push-noti--0, // Khởi tạo
.status-push-noti--1 {
  // Đang xử lý
  color: #61cb2f;
}

// Đang chạy lịch push
.status-push-noti--2 {
  color: #0099df;
}

// Hoàn thành
.status-push-noti--3 {
  color: #2d404e;
}

// Hủy
.status-push-noti--4 {
  color: #ff7337;
}

.withdraw {
  @apply font-medium;

  &-Processing {
    color: #ffc700;
  }

  &-PendingApproval {
    color: #ffc700;
  }

  &-Success {
    color: #50cd89;
  }

  &-Fail {
    @apply text-red-main;
  }

  &-Cancel {
    @apply text-red-main;
  }

  &-Refunded {
    color: #ff4f00;
  }
}

.fs-internal-trade-site-Bid,
.fs-internal-trade-site-ADJ_BID {
  @apply font-medium;
  color: #50cd89;
}

.fs-internal-trade-site-Ask,
.fs-internal-trade-site-ADJ_ASK {
  @apply font-medium text-red-main;
}

.status-transaction {
  @apply text-xs font-semibold;
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 3px 9px;
  white-space: nowrap;
}

.status-transaction--1 {
  color: #61cb2f;
}

.status-transaction--2,
.status-transaction--3 {
  color: #2d404e;
}

.status-transaction--4,
.status-transaction--5 {
  color: #ff7337;
}

.status-transaction--6 {
  color: #82868b;
}
