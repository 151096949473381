.table-Action-Dropdown {
    border: 1px solid rgba(14, 13, 0, 0.2);
    /* transition: height 0.3s ease-in-out;
    transition-delay: 0.1s; */
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    @apply shadow-lg;
}