.heading-1 {
  @apply text-3.5xl lg:text-4.5xl lg:leading-48 font-semibold;
}
.heading-medium {
  @apply text-2xl font-semibold;
}
.heading-2 {
  @apply text-xl font-semibold;
}
.heading-3 {
  @apply text-sm font-semibold;
}
.heading-base {
  @apply text-base font-semibold;
}
