.form {
  label,
  &-label {
    @apply text-base;
  }

  &-group {
    @apply relative mt-2 mb-3;

    &--iconright .form-control {
      @apply pr-16;
    }
  }

  &-input-search {
    height: calc(1.5em + 1.5rem + 2px);
    padding: 0.75rem 2.5rem;
    border-radius: 4px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e5e5e5;
    @apply w-full transition block text-base;

    &:focus {
      @apply shadow-input;
    }

    &:disabled {
      background-color: #f2f2f2;
    }
  }

  &-control {
    height: 38px;
    padding: 6px 8px;
    border-radius: 4px;
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    // background-color: rgba(231, 231, 231, 0.2);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e5e5e5;
    @apply w-full transition block;

    &.padding-small {
      @apply px-2;
    }

    &:focus {
      @apply shadow-input;
    }
    &:disabled {
      background-color: #f2f2f2;
      color: #7d7d7d;
    }

    & option:checked {
      display: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7d7d7d;
    }
  }

  &-select-group {
    @apply flex flex-col;
  }

  &-select-label {
    @apply text-sm font-semibold leading-5;
  }

  &-select-item {
    width: 171px;
    height: 32px;
    border: #e5e5e5 1px solid;
    padding-left: 0 8px 0 8px;
    @apply rounded text-sm font-normal leading-5;

    &:disabled {
      background-color: #f2f2f2;
      color: #7d7d7d;
    }
  }

  &-filter-group {
    margin-bottom: -20px;
    @apply flex items-center gap-5;
  }

  &-filter-button {
    min-height: 32px;
    border: #e5e5e5 1px solid;
    @apply flex items-center rounded py-1 pl-2 pr-4 bg-white;

    &:disabled {
      background-color: #f2f2f2;
      color: #7d7d7d;
    }
  }

  &-filter-button-name {
    @apply text-sm font-normal leading-5 pl-3 pr-3;
  }

  &-blog-preview {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */

    p {
      font-size: 11.5px;
      text-align: left;
      line-height: 18px;
      font-family: 'Plus Jakarta Sans';
      margin-bottom: 8px;
    }

    h1 {
      font-size: 25px;
      line-height: 29px;
      text-align: left;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      margin-bottom: 8px;
    }

    h2 {
      font-size: 17px;
      text-align: left;
      font-family: 'Plus Jakarta Sans';
      line-height: 22px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    h3 {
      font-size: 16px;
      text-align: left;
      font-family: 'Plus Jakarta Sans';
      line-height: 22px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  &-flashcard-preview {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */

    p {
      font-size: 16px;
      text-align: left;
      line-height: 23px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
    }

    h1 {
      font-size: 25px;
      line-height: 32px;
      text-align: left;
      font-family: 'Plus Jakarta Sans';
      margin-bottom: 40px;
    }

    h2 {
      font-size: 17px;
      text-align: left;
      font-family: 'Plus Jakarta Sans';
      line-height: 23px;
    }

    h3 {
      font-size: 16px;
      text-align: left;
      font-family: 'Plus Jakarta Sans';
      line-height: 23px;
    }
  }

  &-select {
    // max-height: 32px;
    // border-radius: 4px;
    // border: 1px solid #E5E5E5;
    @apply text-sm xl:text-base;

    .select__single-value {
      @apply text-sm xl:text-base text-black font-medium;
    }

    .select__control {
      // max-height: 32px;
      border-radius: 4px;
      padding-left: -1px;
      @apply border border-gray-e0;
    }

    .select__value-container {
      padding-left: 6px;
      padding-right: 6px;
    }

    .select__indicator-separator {
      border: none;
      display: none;
    }

    .select__input {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .select__placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .select__input:disabled {
      color: #7d7d7d !important;
    }

    .select__single-value {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .select__single-value.select__single-value--is-disabled {
      color: #7d7d7d !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
    }

    .select__menu {
      @apply p-2 xl:p-4;
    }

    .select__option,
    .select__option--is-selected {
    }
  }

  &-select-32 {
    max-height: 32px;
    border-radius: 4px;
    // border: 1px solid #E5E5E5;
    @apply text-sm xl:text-base;

    .select__single-value {
      @apply text-sm xl:text-base text-black font-normal;
    }

    .select__control {
      max-height: 32px;
      min-height: 32px;
      border-radius: 4px;
      padding-left: -1px;
      border-color: #e5e5e5;
      // @apply border border-gray-e0;

      .select__indicators {
        max-width: 32px;
        max-height: 32px;
      }

      .select__dropdown-indicator {
        max-width: 32px;
        max-height: 32px;
        color: #000000;
        margin-right: -8px;
        margin-top: -4px;
      }
    }

    .select__value-container {
      padding-left: 8px;
      padding-right: 2px;
      // margin-top: -2px;
      @apply text-sm;
    }

    .select__indicator-separator {
      border: none;
      display: none;
    }

    .select__input-container {
      margin: 0px;
      padding: 0px;

      .select__input {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .select__placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .select__input:disabled {
      color: #7d7d7d !important;
    }

    .select__single-value {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .select__single-value.select__single-value--is-disabled {
      color: #7d7d7d !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
    }

    .select__menu {
      @apply p-1 xl:p-2;
    }

    .select__option,
    .select__option--is-selected {
      padding: 2px;
      @apply text-sm;
    }
  }

  // &-select-small {
  //   border-radius: 6px;

  //   .select__single-value {
  //     @apply text-sm text-[#000] font-medium;
  //   }

  //   .select__control {
  //     border-width: 0px !important;
  //     min-height: 0;
  //     max-height: 32px;
  //     padding: 1px 0;
  //     flex-direction: row-reverse;

  //   }

  //   .select__value-container {
  //     padding-left: 0;
  //   }

  //   .select__dropdown-indicator {
  //     padding-top: 0;
  //     padding-bottom: 0;
  //   }
  // }

  &-error & {
    @apply border border-red-accent bg-FFEDE9 text-red-accent;
  }

  &-area {
    padding: 6px 8px;
    border-radius: 4px;
    color: #495057;
    // background-color: rgba(231, 231, 231, 0.2);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e5e5e5;
    @apply w-full transition block text-base;

    &.padding-small {
      @apply px-2;
    }

    &:focus {
      @apply shadow-input;
    }

    &:disabled {
      background-color: #f2f2f2;
    }
  }

  &-color-picker {
    border-right: 24px solid #fff;
    padding-right: 5px;
    margin-top: 20px;
  }

  &-color-input {
    box-sizing: border-box;
    width: 103px;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    outline: none;
    font-family: inherit;
    // text-transform: uppercase;
    text-align: center;
  }

  &-checkbox {
    width: 20px;
    height: 20px;
  }

  &-icon {
    @apply h-full flex items-center absolute left-8 hidden;
  }

  &-icon-right {
    @apply h-full flex items-center absolute top-0 right-8;
  }

  // &-radio input[type="radio"]:checked {
  //   background-color: #0BA5EC;
  //   border-color: #0BA5EC;
  // }

  &-button {
    @apply rounded flex items-center justify-center text-white transition stroke-primary bg-primary cursor-pointer text-sm font-bold px-[18px] py-[11px] min-w-[164px] h-11;

    &:disabled {
      @apply cursor-not-allowed bg-main_background_disable;
      color: #a8a8a8;
    }

    &:hover {
      @apply bg-green-900;
    }
  }
  &-button-seconday {
    @apply bg-main_background_l1 text-primary border border-solid border-primary rounded flex items-center justify-center transition cursor-pointer text-sm font-bold px-[18px] py-[11px] min-w-[164px] h-11;

    &:hover {
      @apply bg-main_background_disable;
    }

    &:disabled {
      @apply cursor-not-allowed bg-main_background_disable border-main_background_disable;
      color: #a8a8a8;
    }
  }

  &-error {
    @apply flex items-center mt-1 mb-2 text-red-main text-sm;

    svg {
      @apply flex-none mr-2 mt-0.5;
    }
  }
}

input[type='checkbox'] {
  accent-color: #00c802 !important;
}

span.form-control {
  background-color: #f2f2f2 !important;
}

div.form-control {
  background-color: #f2f2f2 !important;
}

.text-20-700-26 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.text-18-700-24 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.09px;
}

.text-14-600-20 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.text-14-700-20 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.text-16-700-20 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.required {
  &-after {
    &::after {
      content: '*';
      margin-left: 2px;
      color: #e4033b;
    }
  }

  &-before {
    &::before {
      content: '*';
      margin-right: 2px;
      color: #e4033b;
    }
  }
}

.select-style {
  .report-type-select__indicators {
    display: none;
  }

  .report-type-select__control {
    height: 50px;
  }

  .report-type-select__control:hover,
  .report-type-select__control {
    border-color: #fff;
    outline: none;
    box-shadow: none;
  }
}
