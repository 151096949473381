.header {
  &-name {
    @apply text-2.5xl font-semibold flex items-center;
  }

  &-sub {
    @apply text-sm font-medium;
  }

  &-icon {
    @apply text-xl ml-2 -mt-1.5;
  }
}