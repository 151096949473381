.react-datepicker {
  z-index: 50;
  @apply font-sans;
}

.react-datepicker__month-container {
  z-index: 50;
}

.react-datepicker__input-time-container {
  z-index: 50;
}

.react-datepicker-popper {
  z-index: 50 !important;
}
