.slick {
  &-slider {
    @apply w-full;
  }
  &-list {
    @apply -mx-3;
  }
  &-slide {
    @apply min-w-[300px] sm:min-w-0 w-72 sm:w-auto mr-4 sm:mr-0 pb-2;
    > div {
      @apply px-3;
    }
  }
  &-track {
    margin: 0;
  }
  &-prev:before,
  &-next:before,
  &-disabled {
    @apply hidden;
  }
  &-next {
    @apply -right-2.5 md:-right-6;
  }
  &-prev {
    @apply -left-2.5 md:-left-6;
  }
}
