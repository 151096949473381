.backButton {
  @apply flex items-center space-x-2;

  &-icon {
    @apply flex justify-center items-center border  rounded-full transition;
    
  }
  &-text {
    @apply  font-normal text-sm transition;
  }
}