.page {
  &-sidebar {
    scrollbar-width: none; /* Firefox */
    max-height: calc(100vh - 66px);
    overflow-y: auto;
    padding-bottom: 40px;
  }

  &-sidebar::-webkit-scrollbar {
    width: 0px;
  }
}
