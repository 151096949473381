.btn {
  @apply flex items-center justify-center rounded-md text-gray-4f border border-gray-bd hover:text-primary-e2 hover:border-primary-e2 text-center mx-auto py-2 px-8 transition;

  &-large {
    @apply block  w-full rounded-md text-white text-center py-3.5 mx-auto p-3
    transition;
  }
  &-medium {
    @apply block text-base w-full rounded-md text-white text-center py-2.5 p-3
     transition;
  }
  &-add {
    align-self: flex-start;
    border: 1px solid;
    @apply rounded-md py-1.5 p-3 text-green-7c border-green-7c;
  }

  &-primary {
    @apply rounded flex items-center justify-center text-white transition stroke-primary bg-primary cursor-pointer text-sm font-bold;
    min-width: 80px;
    padding: 9px;

    &:disabled {
      @apply cursor-not-allowed;
      color: #a8a8a8;
      background-color: #f7f7f7;
    }

    &:hover:enabled {
      @apply bg-primary;
    }
  }
}

.btn-btn {
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  padding: 5px 5px;

  &-confirm {
    border: 1px solid rgba(189, 189, 189, 1);
    color: rgba(79, 79, 79, 1);

    &:hover {
      color: rgba(82, 64, 226, 1);
      border: 1px solid rgba(82, 64, 226, 1);
    }
  }

  &-delete {
    color: rgba(255, 65, 65, 0.7);
    border: 1px solid rgba(255, 65, 65, 0.7);
  }

  &-delete:hover {
    color: rgba(255, 65, 65, 1);
    border: 1px solid rgba(255, 65, 65, 1);
    background-color: rgba(255, 65, 65, 0.1);
  }

  &-up {
    color: #c9f2b6;
    border: 1px solid #c9f2b6;
  }

  &-up:hover {
    color: #8dfc5a;
    border: 1px solid #8dfc5a;
  }

  &-down {
    color: #b6dff7;
    border: 1px solid #b6dff7;
  }

  &-down:hover {
    color: #49bafb;
    border: 1px solid #49bafb;
  }

  &-green {
    color: black;
    border: 1px solid rgba(67, 255, 44, 0.6);
  }

  &-green:hover {
    color: black;
    border: 1px solid rgba(67, 255, 44, 1);
  }

  &-blue {
    color: rgba(44, 52, 255, 1);
    border: 1px solid rgba(44, 52, 255, 0.6);
  }

  &-blue:hover {
    color: rgba(44, 52, 255, 1);
    border: 1px solid rgba(44, 52, 255, 1);
    background-color: rgba(44, 52, 255, 0.1);
  }

  &-red {
    color: black;
    border: 1px solid rgba(255, 44, 44, 1);
  }

  &-yellow {
    color: black;
    border: 1px solid rgba(237, 255, 44, 1);
  }

  &-orange {
    color: black;
    border: 1px solid rgba(255, 165, 0, 1);
  }

  &-DFFF9A {
    color: black;
    border: 1px solid #dfff9a;
    background-color: #dfff9a;
  }

  &-FFB198 {
    color: black;
    border: 1px solid #ffb198;
    background-color: #ffb198;
  }

  &-FFED8A {
    color: black;
    border: 1px solid #ffed8a;
    background-color: #ffed8a;
  }

  &-C9F2B6 {
    color: black;
    border: 1px solid #c9f2b6;
    background-color: #c9f2b6;
  }

  &-B6DFF7 {
    color: black;
    border: 1px solid #b6dff7;
    background-color: #b6dff7;
  }

  &-D1E1CF {
    color: black;
    border: 1px solid #d1e1cf;
    background-color: #d1e1cf;
  }
}
